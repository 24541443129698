<template>
    <vue-plotly v-if="loaded" :data="dataset" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'LineChart',
  components: { VuePlotly },
  props: {
    xdata: Array,
    ydata: Array,
    zorgkantoor: Array,
    xtitle: {
      type: String,
      default: ''
    },
    ytitle: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: 1
    },
    tickvals: {
      type: Array,
      default: null,
    },
    tickfont_size:{
      type: Number,
      default:10
    },
    ticktext: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      dataset: [],
      loaded: false,
      colors: [],
      widths: [],
      layout: {
        hovermode: 'closest',
        margin: {
          l: 70,
          r: 50,
          b: 40,
          t: 30,
          pad: 4
        },
        height: 400,
        yaxis: {
          automargin:true,
          title: {
            standoff:20,
            text: this.ytitle,
            font: {
              family: 'Gotham',
              size: 14
            }
          },
          range: [0, this.max],
          tickformat: ',.0%',
        },
        xaxis: {
          automargin:true,
          // tickmode: "linear", //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
          // tick0: -4,
          // dtick: 1,
          tickvals: this.tickvals,
          ticktext: this.ticktext,
          tickfont:{
            size:this.tickfont_size
          },
          title: {
            standoff:20,
            text: this.xtitle,
            font: {
              family: 'Gotham',
              size: 14
            }
          },
          size: 10
        }
      },
      options:{
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  watch: {
    '$store.state.selectedRegion': function () {
      this.get_data(this.xdata,this.ydata,this.zorgkantoor)
    }
  },
  methods: {
    get_data (xdata, ydata, zorgkantoor) {
      var colorArray = [];
      var widthArray = [];
      for (var c = 0; c<zorgkantoor.length; c++) {
        if (zorgkantoor[c] === this.$store.state.selectedRegion) {
          colorArray.push('rgba(20,66,115,1.00))')
          widthArray.push(4)
        }
        else if (zorgkantoor[c] === 'Landelijk') {
          colorArray.push('rgba(51,127,100,1.00))')
          widthArray.push(2)
        }
        else {
          colorArray.push('rgba(180,180,180,0.8)')
          widthArray.push(1)
        }
      }
      this.colors = colorArray
      this.widths = widthArray
      var dataArray = [];
      for (var i = 0 ; i < xdata.length ; i++ ) {
      var result = {
        x: xdata[i],
        y: ydata[i],
        // type: 'scatter',
        mode: 'lines',
        name: zorgkantoor[i],
        line: {
          color: this.colors[i],
          width: this.widths[i]
        }
      }
      dataArray.push(result)
      this.dataset=dataArray
      this.loaded = true
      }
    }
  },
  mounted () {
    this.get_data(this.xdata,this.ydata,this.zorgkantoor)
  },
  // computed: {
  //   data () {
  //     return [
  //       this.get_data(this.xdata, this.ydata, this.zorgkantoor)
  //     ]
  //   }
  // }
}

</script>
